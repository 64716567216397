<template>
  <div>
    <div class="dis jus_content">
      <div class="flex align_center">
        <div class="icon"></div>
        <div class="service">企业荣誉</div>
      </div>
      <div class="add" style="" @click="adddynamic()">添加企业荣誉</div>
    </div>

    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="id" label="ID" min-width="100" align="center">
      </el-table-column>
      <el-table-column
        prop="title"
        label="荣誉名称"
        min-width="180"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="添加时间"
        min-width="180"
        align="center"
      >
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="props">
          <!-- <el-button type="text">编辑</el-button> -->
          <el-button type="text" @click="amend(props.row.id)">编辑</el-button>
          <el-button type="text" @click="del(props.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      v-loading="loading"
      title="添加企业荣誉"
      :visible.sync="setcountlogin"
      width="600px"
      center
    >
      <div>
        <div class="flex align_center marb_20">
          <div class="font_20" style="width: 100px">荣誉标题</div>
          <el-input v-model="honortitle"></el-input>
        </div>

        <el-upload
          ref="uploadList"
          list-type="picture-card"
          class="avatar-uploader"
          action="#"
          accept=".jpg,.jpeg,.png"
          :multiple="true"
          name="file2"
          :limit="1"
          :auto-upload="true"
          :file-list="loadList"
          :http-request="httpreq"
          :before-upload="changeUpload"
          :on-exceed="handleExceed"
          :on-remove="handleRemove"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </div>
      <div class="flex mat_30">
        <div class="enter" @click="honoradd()">确认添加</div>
        <div class="marl_30 cancel" @click="setcountlogin = false">取消</div>
      </div>
    </el-dialog>

    <!-- 修改 -->

    <el-dialog
      v-loading="amendloading"
      title="修改企业荣誉"
      :visible.sync="amendlogin"
      width="600px"
      center
    >
      <div>
        <div class="flex align_center marb_20">
          <div class="font_20" style="width: 100px">荣誉标题</div>
          <el-input v-model="amendtitle"></el-input>
        </div>
        <div class="flex">
          <el-upload 
            ref="uploadList"
            list-type="picture-card"
            class="avatar-uploader"
            action="#"
            accept=".jpg,.jpeg,.png"
            :multiple="true"
            name="file2"
            :limit="1"
            :auto-upload="true"
            :file-list="uploadList"
            :http-request="uphttpreq"
            :before-upload="changeUpload"
            :on-exceed="handleExceed"
            :on-remove="uphandleRemove"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
      </div>
      <div class="flex mat_30">
        <div class="enter" @click="honorup()">确认修改</div>
        <div class="marl_30 cancel" @click="amendlogin = false">取消</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      uploadList: [], //更新
      loadList: [],
      imgList: [],
      loads: [],
      img: "",
      loading: false,
      allTableData: [],
      honorimg: [],
      setcountlogin: false,
      honortitle: "",
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 10, // 总数
        page_size: 10, // 1页显示多少条
        page_sizes: [10, 20, 50, 90], //每页显示多少条
        layout: "total, sizes, prev, pager, next, jumper", // 翻页属性
      },

      //修改
      amendloading: false,
      amendlist: [],
      amendtitle: "",
      amendlogin: false,
      amendimg: "",
      uploads: [],
      amend_Id: "", //修改id
      uploading: false, //判断是否上传
    };
  },
  created() {
    this.gethonour();
    // this.authentication();
  },

  methods: {
    // 认证
    authentication() {
      let data = {
        token: localStorage.eleToken,
      };
      console.log(data, "data");
      this.$get("/enterprise_user_status", data).then((res) => {
        console.log(res, "res456");
        if (res.status === 1) {
          this.setcountlogin = true;
        } else if (res.status === 2) {
          // setInterval(() => {
            // location.href = "http://sso.zhenchangkj.com:9000/realname/index";
            // location.href = "http://sso.smeqh.cn:9000/realname/index";
            this.$message({
              type: "info",
              message: "您的账号还未实名认领，请认领通过之后再进行下一步操作",
            });
          // }, 2000);
        }
      });
    },

    adddynamic() {
      this.authentication();
    },
    //上传图片
    upimg() {
      return new Promise((resolve, reject) => {
        this.loading = true;
        let formdata = new FormData();
        formdata.append("file", this.loads);
        this.$post("upload_img", formdata).then((res) => {
          console.log(res, "res");
          this.img = res.address;
          this.imglogin = false;

          resolve();
        });
      });
    },

    //上传图片
    upamendimg() {
      return new Promise((resolve, reject) => {
        this.amendloading = true;
        let formdata = new FormData();
        formdata.append("file", this.uploads);
        this.$post("upload_img", formdata).then((res) => {
          console.log(res, "res");
          this.amendimg = res.address;
          this.amendloading = false;
          resolve();
        });
      });
    },

    //删除
    del(id){
      
      let data = {
            token: localStorage.eleToken,
            id: id,
          };
          console.log(data, "updata");

          this.$post("info_honour_del", data).then((res) => {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.gethonour();
          });
    },

    //修改
    honorup() {
      if (this.amendtitle == "") {
        this.$message({
          type: "info",
          message: "请输入荣誉标题",
        });
      } else {
        if (this.uploading == false) {
          let data = {
            token: localStorage.eleToken,
            title: this.amendtitle,
            img: this.amendimg,
            id: this.amend_Id,
          };
          console.log(data, "updata");

          this.$post("info_honour_upd", data).then((res) => {
            this.amendlogin = false;
            this.amendloading = false;
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.gethonour();
            console.log(res, "res");
          });
        } else {
          Promise.all([this.upamendimg()])
            .then((res) => {
              let data = {
                token: localStorage.eleToken,
                title: this.amendtitle,
                img: this.amendimg,
                id: this.amend_Id,
              };
              console.log(data, "updata");

              this.$post("info_honour_upd", data).then((res) => {
                this.amendlogin = false;
                this.amendloading = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.gethonour();
                console.log(res, "res");
              });
            })
            .catch((error) => {
              console.log("error==>", error);
            });
        }
      }
    },

    //提交
    honoradd() {
      if (this.honortitle == "") {
        this.$message({
          type: "info",
          message: "请输入荣誉标题",
        });
      } else {
        Promise.all([this.upimg()])
          .then((res) => {
            let data = {
              token: localStorage.eleToken,
              title: this.honortitle,
              img: this.img,
            };
            console.log(data, "adddata");

            this.$post("info_honour_add", data).then((res) => {
              this.loading = false;
              this.setcountlogin = false;
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.gethonour();
              console.log(res, "res");
            });
          })
          .catch((error) => {
            console.log("error==>", error);
          });
      }
    },
    //请求列表
    gethonour(item) {
      let data = {
        token: localStorage.eleToken,
        page: item ? item : this.paginations.page_index,
        limit: this.paginations.page_size,
      };
      console.log(data, "data");
      this.$post("info_honour_lst", data).then((res) => {
        this.allTableData = res.data;
        console.log(this.allTableData, " this.allTableData ");
        if (res.count === "") {
          this.paginations.total = 0;
        } else {
          this.paginations.total = res.count;
        }
        if (this.allTableData.length != 0) {
          this.tableData = this.allTableData.filter((item, index) => {
            return index < this.paginations.page_size;
          });
        } else {
          this.tableData = [];
        }
      });
    },
    //修改获取之前填写信息
    amend(id) {
      this.amend_Id = id;
      let data = {
        token: localStorage.eleToken,
        id: id,
      };
      console.log(data, "adddata");

      this.$get("info_honour_lst_d", data).then((res) => {
        let list = []
        this.amendlogin = true;
        this.amendtitle = res.data.title;
        list.push(res.data.img);
        console.log(res,'res')
        this.uploadList =  list.map((item) => {
          console.log(item, "item");
          return {
            name: item.img,
            url: item.img ? item.img : item,
            img: item.img,
          };
        });

        this.amendimg = res.data.img
        console.log(this.amendimg, "this.amendimg");
      });
    },

    // 分页
    handleSizeChange(page_size) {
      console.log(page_size, "page_size");
      this.paginations.page_index = 1;
      this.paginations.page_size = page_size;
      this.gethonour();
      this.tableData = this.allTableData.filter((item, index) => {
        return index < page_size;
      });
    },
    handleCurrentChange(page) {
      console.log(page, "handleCurrentChangepage");
      //获取当前页
      let index = this.paginations.page_size * (page - 1);
      //获取数据总数
      let nums = this.paginations.page_size * page;
      this.gethonour(page);
      //容器
      let tables = [];
      for (let i = index; i < nums; i++) {
        if (this.allTableData[i]) {
          tables.push(this.allTableData[i]);
        }
        this.tableData = tables;
      }
    },

    httpreq(load) {
      this.loads = load.file;
      console.log(this.loads, "this.loads");
      // let formdata = new FormData();
      // formdata.append("file", load.file);
      // this.$post("upload_img", formdata).then((res) => {
      //   this.uploadList.push(res.address);
      // });
    },
    uphttpreq(load) {
      this.uploads = load.file;
      this.uploading = true;
      // let formdata = new FormData();
      // formdata.append("file", load.file);
      // this.$post("upload_img", formdata).then((res) => {
      //   this.uploadList.push(res.address);
      // });
    },
    changeUpload(file, fileList) {
      let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "jpg";
      const extension1 = testmsg === "jpeg";
      const extension2 = testmsg === "png";

      if (!extension && !extension1 && !extension2) {
        this.$message({
          message: "上传文件只能是.jpg、.jpeg、.png格式!",
          type: "warning",
          duration: 1500,
        });
        console.log(this.option, " this.option");
        this.img = "";

        this.headimg = "";
        this.uploadList = "";
        return false;
      }
      let formData = new FormData();

      formData.append("file", file.raw);
    },
    handleRemove(file, fileList) {
      this.loads = fileList;
      console.log(this.loads, "this.loads");
    },
    uphandleRemove(file, fileList) {
      this.uploads = fileList;
      console.log(this.loads, "this.loads");
    },
    handleExceed() {
      this.$message({
        message: "最大上传数量为1张!",
        type: "warning",
        duration: 1500,
      });
    },
  },
};
</script>
<style>
.icon {
  width: 4px;
  height: 19px;
  background: #fb1010;
  border-radius: 2px;
}

.service {
  height: 30px;
  font-size: 24px;
  font-family: Source Han Sans SC Medium, Source Han Sans SC Medium-Medium;
  font-weight: 500;
  text-align: left;
  color: #333333;
  line-height: 30px;
  margin-left: 10px;
}
.add {
  width: 198px;
  height: 40px;
  background: #ff6618;
  border-radius: 9px;
  color: #fff;
  line-height: 40px;
  text-align: center;
}
.enter {
  width: 137px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #ff6618;
  border-radius: 10px;
  color: #fff;
}

.cancel {
  width: 137px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 10px;
  background: #cfcfcf;
  color: #fff;
}
</style>
